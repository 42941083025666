import { defineStore } from 'pinia';
import { store } from '@/stores';
import { ElLoading } from 'element-plus';
import { useCache } from '@/hooks/useCache';
import {
    Appointment,
    Consultation,
    Doctor,
    Patient,
    PaymentItem,
    PaymentDiscountItem
} from '@model/entities';
import { ConsultationUpsertDTO } from '@model/dtos';
import { eventEmitter } from '@/services/events';
import { AppointmentService, ConsultationService, PaymentService } from '@/services';
import { cloneDeep } from 'lodash';
import date from '@model/utils/date';
import { notify } from '@/utils/notify';
import { usePrescriptionFacadeStore } from './prescription-facade.store';
import { handleError } from '@/utils/handleError';
import {
    PaymentConsultationPreviewDTO,
    PrescriptionFacadeStateVO,
    ConsultationPatientFacadeStateVO,
    ConsultationDiagnosisFacadeStateVO,
    ConsultationPaymentFacadeStateVO,
    PaymentPackagePreviewDTO
} from '@model/vos';
import { usePaymentFacadeStore } from './payment-facade.store';
import { usePatientDataviewStore } from '@/stores/modules/dataview';
import { PatientCaution } from '@model/models';

const { wsCache } = useCache('sessionStorage');
export interface ConsultationFacadeState {
    // UI State
    wordlistSearchText: string;

    _appointmentId: number;
    _appointment: Appointment;
    _consultation: Consultation;
    _doctor: Doctor;
    _patient: Patient;

    // Dto
    _consultationId: number;
    massage: ConsultationUpsertDTO['massage'];
    acu: ConsultationUpsertDTO['acu'];
    diagnosis: ConsultationDiagnosisFacadeStateVO;
    patient: ConsultationPatientFacadeStateVO;
    // _healthcareVocher: any;
}

const defaultState: ConsultationFacadeState = {
    wordlistSearchText: '',

    _appointmentId: null,
    _consultationId: null,
    _appointment: null,
    _consultation: null,
    _doctor: null,
    _patient: null,

    acu: '',

    diagnosis: ConsultationDiagnosisFacadeStateVO.getDefaultValue(),

    massage: '',

    patient: ConsultationPatientFacadeStateVO.getDefaultValue(),
}

export const useConsultationFacadeStore = defineStore({
    id: 'consultation-facade',
    state: (): ConsultationFacadeState => cloneDeep(defaultState),
    getters: {
        appointmentId(): number {
            return this._appointmentId;
        },
        patientId(): Patient['id'] {
            return this._patient?.id;
        },
        sickLeaveCount(): number {
            if (!this.diagnosis._sickLeaveDateRange) {
                return 0;
            }
            if (this._consultation.override_sick_leave_date_count) {
                return this._consultation.override_sick_leave_date_count;
            }
            try {
                return date.getSickLeaveDays(this.diagnosis._sickLeaveDateRange[0], this.diagnosis._sickLeaveDateRange[1], {
                    sick_leave_start_from_pm: this.diagnosis.sick_leave_start_from_pm,
                    sick_leave_end_at_am: this.diagnosis.sick_leave_end_at_am,
                });
            } catch (e) {
                notify.error(e.message);
                return 0;
            }
        },
    },
    actions: {
        async initWithAppointment(appointment: Appointment) {
            const loading = ElLoading.service({
                fullscreen: true,
                text: '載入中...',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            try {
                this.wordlistSearchText = '';
                this._appointmentId = appointment.id;
                this._appointment = appointment;
                this._doctor = this._appointment.doctor;
                this._patient = this._appointment.patient;

                this._consultationId = this._appointment.consultation.id ?? null;
                this._consultation = this._appointment.consultation;

                this.diagnosis = this._appointment.consultation ?
                    ConsultationDiagnosisFacadeStateVO.fromEntity(this._appointment.consultation)
                    : ConsultationDiagnosisFacadeStateVO.getDefaultValue();

                this.acu = this._consultation.acu ? this._consultation.acu : defaultState.acu;
                this.massage = this._consultation.massage ? this._consultation.massage : defaultState.massage;

                const patientDataviewStore = usePatientDataviewStore();
                await patientDataviewStore.init(this._appointment?.patient, this._consultation);

                const paymentDataviewStore = usePaymentFacadeStore();
                await paymentDataviewStore.init({
                    payment: this._appointment.payment,
                    patient: this._appointment.patient,
                });

                const prescriptionDataviewStore = usePrescriptionFacadeStore();
                await prescriptionDataviewStore.init(this._consultation.prescriptions, this._appointment.clinic_id);
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
        async init(appointmentId: number) {
            const loading = ElLoading.service({
                fullscreen: true,
                text: '載入中...',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            try {
                this.wordlistSearchText = '';
                this._appointmentId = appointmentId;
                this._appointment = await AppointmentService.oneDeep(this._appointmentId);
                this._doctor = this._appointment.doctor;
                this._patient = this._appointment.patient;

                this._consultationId = this._appointment.consultation.id ?? null;
                this._consultation = this._appointment.consultation;

                this.diagnosis = this._appointment.consultation ?
                    ConsultationDiagnosisFacadeStateVO.fromEntity(this._appointment.consultation)
                    : ConsultationDiagnosisFacadeStateVO.getDefaultValue();

                this.acu = this._consultation.acu ? this._consultation.acu : defaultState.acu;
                this.massage = this._consultation.massage ? this._consultation.massage : defaultState.massage;

                const patientDataviewStore = usePatientDataviewStore();
                await patientDataviewStore.init(this._appointment?.patient, this._consultation);

                const paymentDataviewStore = usePaymentFacadeStore();
                await paymentDataviewStore.init({
                    payment: this._appointment.payment,
                    patient: this._appointment.patient,
                });

                const prescriptionDataviewStore = usePrescriptionFacadeStore();
                await prescriptionDataviewStore.init(this._consultation.prescriptions, this._appointment.clinic_id);
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
        async injectDiagnosis(consultation: Consultation) {
            this.diagnosis = ConsultationDiagnosisFacadeStateVO.fromEntity(consultation, { excludeSickLeave: true });
        },
        async injectAcu(consultation: Consultation) {
            this.acu = consultation.acu;
        },
        async injectMassage(consultation: Consultation) {
            this.massage = consultation.massage;
        },
        async validate() {
            const loading = ElLoading.service({ fullscreen: true });
            try {
                if (!this.diagnosis.content) {
                    throw Error('請填寫主訴');
                }
                if (!this.diagnosis.diagnosis_ill) {
                    throw Error('請填寫病名');
                }
                if (this.diagnosis.has_sick_leave) {
                    if (!this.diagnosis._sickLeaveDateRange) {
                        throw Error('請填寫病假開始/完結日期');
                    }

                    if (date.isDayBefore(this.diagnosis._sickLeaveDateRange[0], this._consultation.consult_dt)) {
                        throw Error('病假不能在診治日期以前開始');
                    }
                }
                const prescriptionDataviewStore = usePrescriptionFacadeStore();
                if (!prescriptionDataviewStore.hasPrescription) {
                    await this.save();
                    return;
                }
                const patientDataviewStore = usePatientDataviewStore();
                await prescriptionDataviewStore.validate(patientDataviewStore.patientAlert);
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
        async save(issues?: any[]) {
            const loading = ElLoading.service({ fullscreen: true });
            try {
                const prescriptionDataviewStore = usePrescriptionFacadeStore();
                const paymentDataviewStore = usePaymentFacadeStore();
                const patientDataviewStore = usePatientDataviewStore();

                if (!this._consultation.consult_confirmed || this._appointment.status === 'payment' || this._appointment.status === 'arrived') {
                    const prescriptionPaymentItems = await this.previewPrescriptionPrice();
                    await paymentDataviewStore.setPrescriptionPrice(prescriptionPaymentItems);

                    const consultationPaymentItems = await this.previewConsultationPrice();
                    await paymentDataviewStore.setConsultationPrice(consultationPaymentItems);
                }

                const res: Partial<Consultation> = await ConsultationService.save({
                    appointment_id: this._appointmentId,
                    consultation_id: this._consultationId,
                    patient: patientDataviewStore.consultationSaveParam,
                    diagnosis: {
                        ...this.diagnosis,
                        _sickLeaveDateRange: this.diagnosis._sickLeaveDateRange && this.diagnosis._sickLeaveDateRange.length >= 2 ?
                            [
                                date.toDayjs(this.diagnosis._sickLeaveDateRange[0]).format('YYYY-MM-DD'),
                                date.toDayjs(this.diagnosis._sickLeaveDateRange[1]).format('YYYY-MM-DD')
                            ] : null,
                    },
                    acu: this.acu,
                    massage: this.massage,
                    prescriptions: prescriptionDataviewStore.prescriptions.filter(x => x.items.length > 0).map((vo, index) => {
                        // TODO: now this is shared across all prescriptions, need to refactor
                        vo.pharmacy_option.delivery_info = {
                            mobile: patientDataviewStore.patient.delivery_info.mobile,
                            address: patientDataviewStore.patient.delivery_info.address,
                            name: patientDataviewStore.patient.name,
                        };
                        const issueMatch = issues ? issues.filter(x => Number(x.index) === index) : [];
                        if (issueMatch.length > 0) {
                            return PrescriptionFacadeStateVO.toPrescriptionsCreateParam(vo, issueMatch[0]);
                        }
                        return PrescriptionFacadeStateVO.toPrescriptionsCreateParam(vo);
                    }),
                    payment: ConsultationPaymentFacadeStateVO.toPaymentCreateParam(paymentDataviewStore.payment),
                });
                eventEmitter.emit('consultaion:created', res);
            } catch (e) {
                if (e.message === '') {

                }
                handleError(e);
            } finally {
                loading.close();
            }
        },
        async previewPrescriptionPrice(): Promise<PaymentItem[]> {
            const prescription = usePrescriptionFacadeStore();
            return prescription.previewPrescriptionPrice(this._appointment.clinic_id);

        },
        async previewConsultationPrice(): Promise<{ items: PaymentItem[], discounts: PaymentDiscountItem[] }> {
            return PaymentService.previewConsultation(PaymentConsultationPreviewDTO.fromAppointment(this._appointment));
        },
        async previewPackagePrice(): Promise<{ items: PaymentItem[], discounts: PaymentDiscountItem[] }> {
            return PaymentService.previewPackage(PaymentPackagePreviewDTO.fromAppointment(this._appointment));
        },
        async previewPrescription(patientCaution?: PatientCaution) {
            const loading = ElLoading.service({ fullscreen: true });
            try {
                const prescription = usePrescriptionFacadeStore();
                await prescription.previewPrescription({
                    appointmentId: this._appointmentId,
                    consultationId: this._consultationId,
                    patient: this._patient,
                    caution: patientCaution,
                    diagnosis: this.diagnosis,
                });
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
        async printPrescription(consultationDate: Date | string, patientCaution?: PatientCaution) {
            const prescription = usePrescriptionFacadeStore();
            await prescription.printPrescription({
                appointmentId: this._appointmentId,
                consultationId: this._consultationId,
                consultationDate: consultationDate,
                patient: this._patient,
                caution: patientCaution,
                diagnosis: this.diagnosis,
            });
        }
    }
});

export const useConsultationFacadeStoreWithOut = () => {
    return useConsultationFacadeStore(store);
};
