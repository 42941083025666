import { defineStore } from 'pinia';
import { store } from '@/stores';
import { Herb } from '@model/entities';
import { useCache } from '@/hooks/useCache';
import { Appointment, Doctor, DoctorPrescription, Patient } from '@model/entities';
import date from '@model/utils/date';
import { notify } from '@/utils/notify';
import { LocalStorage } from '@/utils/local-storage';
import { DoctorPrescriptionService, HerbService } from '@/services';
import { DoctorPrescriptionStateVO } from '@model/vos';
import { DoctorPrescriptionUpsertDTO } from '@model/dtos';
import router from '@/router';
import { eventEmitter } from '@/services/events';
import { useUserStore, useUserStoreWithOut } from '@/stores/modules';
import { HARDCODE_PHARM_ID } from '@/utils/hardcode';

const { wsCache } = useCache('localStorage');

export interface DoctorPrescriptionState {
    current?: DoctorPrescriptionStateVO['id'];
    selectedClinic?: number;
    state?: DoctorPrescriptionStateVO;
}

const defaultState: DoctorPrescriptionState = {
    current: undefined,
    state: undefined,
    selectedClinic: undefined,
}

export const useDoctorPrescriptionStore = defineStore({
    id: 'doctor-prescription',
    persist: {
        enabled: true,
    },
    state: (): DoctorPrescriptionState => defaultState,
    actions: {
        async init(doctorPrescriptionId?: DoctorPrescription['id']) {
            const user$ = useUserStore();
            this.selectedClinic = user$.v2SelectClinicId;
            if (doctorPrescriptionId) {
                this.current = doctorPrescriptionId;
                const entity = await DoctorPrescriptionService.oneDeep<DoctorPrescription>(doctorPrescriptionId);
                this.state = DoctorPrescriptionStateVO.fromEntity(entity);
                if (entity.is_pharmacy) {
                    this.selectedClinic = HARDCODE_PHARM_ID;
                }
            } else {
                this.current = undefined;
                this.state = DoctorPrescriptionStateVO.getDefaultValue();
            }
            console.log('init', this.state);
            await this.refreshHerbStock();
            await this.render();
        },
        async reset() {
            if (this.current) {
                this.state = DoctorPrescriptionStateVO.fromEntity(await DoctorPrescriptionService.oneDeep(this.current));
            } else {
                this.state = DoctorPrescriptionStateVO.getDefaultValue();
            }
            await this.refreshHerbStock();
            await this.render();
        },
        async resetItems(){
            this.state.items = [];
        },
        async render() {
            eventEmitter.emit('datagrid:sync', { id: `doctor-prescription-editor`, data: this.state.items });
        },
        async refreshHerbStock() {
            const res = await HerbService.getManyStockByCodeAndClinic(this.state.items.map(x => ({ code: x.herb_code, clinicId: this.selectedClinic })));
            res.forEach(x => {
                const item = this.state.items.find(y => y.herb_code === x.code);
                if (item) {
                    item.stock = x.stock;
                }
            });
        },
        async submit() {
            console.log(this.state);
            const user$ = useUserStore();
            const dto: DoctorPrescriptionUpsertDTO = {
                id: this.current ?? null,
                doctor_id: this.state.doctor_id ?? user$.doctor?.id ?? null,
                display_name: this.state.display_name,
                description: this.state.description,
                clinic_id: this.selectedClinic,
                usage: this.state.usage,
                caution: this.state.caution,
                remark: this.state.remark,
                internal_remark: this.state.internal_remark,
                share_config: this.state.share_config,
                items: this.state.items.map((x, index) => ({
                    order: index,
                    herb_code: x.herb_code,
                    herb_name: x.herb_name,
                    amount: x.amount,
                    unit: x.unit,
                    method: x.method ?? "",
                })),
                permissions: this.state.permissions.map(x => ({
                    scope: x.scope,
                    user_id: x._type === 'user' ? x.user_id : null,
                    user_group_id: x._type === 'usergroup' ? x.user_group_id : null,
                })),
            }
            await DoctorPrescriptionService.create(dto);
            notify.saved();
        },
        async add(herbId: Herb['id']) {
            const herb = await HerbService.one<Herb>(herbId);
            if (this.state.items.find(x => x.herb_code === herb.code)) {
                notify.error(`已加入藥材 ${herb.code} ${herb.name_zh}`);
                return null;
            }
            const item = {
                herb_code: herb.code,
                herb_name: herb.name_zh,
                order: null,
                amount: 0,
                stock: herb.stock.stock,
                unit: herb.dispense_unit.name_zh,
                method: null,
            }
            this.state.items.push(item);
            return item;
        }
    }
});
