<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
import { eventEmitter } from "@/services/events";
import PatientHistoryInjectComponent from "./patient-history-inject.component.vue";
import { useUserStore } from "@/stores/modules";
import { notify } from "@/utils/notify";
import { usePreferenceStore } from "@/stores/modules/preference";

@Options({
    components: {
        PatientHistoryInjectComponent,
    },
})
export default class PatientHistorySidebar extends Vue {
    patientHistory$ = usePatientHistoryDataviewStore();
    user$ = useUserStore();

    async handleClickRow(e) {
        const id = parseInt(e.target.dataset.id);
        await this.patientHistory$.loadConsultation(id);
        eventEmitter.emit("patient-history-tab:change");
    }

    handleClickPatientVital() {
        eventEmitter.emit("patient-vital-dialog:open", this.patientHistory$.patientId);
    }

    handleClickFixConsultation() {
        notify.notImplemented();
    }
}
</script>

<template lang="pug">
.side-bar#patient-history-sidebar
    .title 紀錄日期
    .history(v-loading="patientHistory$.loading")
        .row(
            v-for="c in patientHistory$.consultationList" 
            :key="c.id" 
            @click="handleClickRow"
            :data-id="c.id"
            :class="patientHistory$.currentConsultationId === c.id ? 'active' : ''" 
        )
            template(v-if="c.appointment.type === 'regform'")
                div (登記表)
                .row-item {{ $filters.datetime(c.consult_dt) }}
            template(v-else)
                div(v-if="c.appointment.status === 'cancelled'" style="color: red") (已取消)
                .row-item(:class="c.appointment.status === 'cancelled' ? 'cancelled': ''") {{ $filters.datetime(c.consult_dt) }}

    PatientHistoryInjectComponent(ref="injectComponent")
    Button(@click="handleClickPatientVital") 生命表徵
    Button(@click="handleClickFixConsultation" v-if="patientHistory$.currentConsultationId && user$.hasPermission('other.fix')") 資料修正
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.side-bar {
    width: 100%;
}

.cancelled {
    text-decoration: line-through;
}

.history {
    padding: 5px;
    width: 100%;
    border: 1px dotted $primary-color;
    height: 300px;
    overflow-y: scroll;
}

.row-item {
    user-select: none;
    pointer-events: none;
}

.row {
    padding-left: 5px;
    height: 30px;
    @include flex-center();
    justify-content: flex-start;

    &.active {
        background: lighten($primary-color, 50%);
    }

    &:hover {
        color: $primary-color;
        cursor: pointer;
    }
}
</style>
