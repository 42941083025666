<script lang="ts">
import { toRefs } from "@vue/reactivity";
import { Options, Vue } from "vue-property-decorator";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { handleError } from "@/utils/handleError";
import { showButtonRipple } from "@/utils/animation/show-button-ripple";

@Options({
    components: {},
})
export default class Button extends Vue {
    @Prop({
        validator: (val: string) => {
            if (val === "") return true;
            return ["medium", "small", "mini"].indexOf(val) !== -1;
        },
    })
    size: string;

    @Prop({
        validator: (val: string) => {
            if (val === "") return true;
            return ["primary", "secondary", "tertiary", "success", "warning", "danger", "info", "link", "text"].indexOf(val) !== -1;
        },
    })
    type: string;

    @Prop({
        type: Boolean,
    })
    circle: boolean;

    @Prop({
        type: Boolean,
    })
    loading: boolean;

    @Prop({
        type: Boolean,
    })
    disabled: boolean;

    @Prop()
    icon?: string;

    get computedClass() {
        return [this.size ? `btn-${this.size}` : "", this.type ? `${this.type}` : "", this.circle ? "btn--is-circle" : "", this.loading ? "is-loading" : "", this.disabled ? "is-disabled" : ""];
    }

    handleClick(e: any) {
        showButtonRipple(e, this.$el);
    }
}
</script>
<template lang="pug">
button.btn(
    :class="computedClass",
    :disabled="disabled || loading",
    @click.prevent="handleClick"
)
    el-icon.spin(v-if="loading")
        Loading
    i.el-icon(:class="icon", v-else-if="icon")
    span
        slot
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 10px 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    min-width: 64px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    width: 100%;
    padding: 12px 30px;
    border-radius: 5px;
    text-transform: initial;

    &.primary {
        color: rgb(255, 255, 255);
        font-weight: 500;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: $primary-color;

        &:disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12);
        }

        &:active {
            box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        }

        &:hover:not(:disabled) {
            text-decoration: none;
            background-color: darken($primary-color, 10%);
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
        }
    }

    &.link {
        font-weight: 700;
        color: rgb(15, 76, 129);
        background-color: transparent;
        box-shadow: none;

        &:disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            text-decoration: none;
            background-color: rgba(15, 76, 129, 0.04);
        }

        &:hover:not(:disabled) {
            text-decoration: none;
            background-color: rgba(15, 76, 129, 0.04);
        }
    }

    &.secondary {
        font-weight: 700;
        background-color: transparent;

        &:disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12);
        }

        &:hover:not(:disabled) {
            text-decoration: none;
            background-color: rgba(15, 76, 129, 0.04);
        }
    }

    &.tertiary {
        font-weight: 700;
        background-color: #f6ff9c;

        &:disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12);
        }

        &:hover:not(:disabled) {
            text-decoration: none;
            background-color: lighten(#f6ff9c, 10%);
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    & [class*="el-icon"] + span {
        margin-left: 6px;
    }

    .spin {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }
    @-moz-keyframes spin {
        50% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        50% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        50% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
</style>
