import { Appointment, Consultation, HerbStockIn, HerbStockAdjust, HerbStockOrder, Patient, Payment, PackagePurchaseRequest, DoctorPrescription } from "@model/entities";

const formatId = (prefix: string, id?: number, digit: number = 9): string => {
    if (!id) {
        return null;
    }
    return prefix + `${id}`.padStart(digit, '0');
}

const parseId = (prefix: string, formattedId?: string): number => {
    if (!formattedId) {
        return null;
    }
    return parseInt(formattedId.replace(prefix, ''));
}

const formatPatientId = (id: Patient['id']): string => {
    return 'P' + `${id}`.padStart(9, '0');
}

const formatPaymentId = (id: Payment['id']): string => {
    return 'I' + `${id}`.padStart(9, '0');
}

const formatHerbStockOrderId = (id: HerbStockOrder['id']): string => {
    return formatId('PO', id, 9);
}

const parseHerbStockOrderId = (formattedHerbStockOrderId: string): HerbStockOrder['id'] => {
    return parseId('PO', formattedHerbStockOrderId);
}

const formatHerbStockInId = (id: HerbStockIn['id']): string => {
    return formatId('SI', id);
}

const parseHerbStockInId = (formattedHerbStockInId: string): HerbStockIn['id'] => {
    return parseId('SI', formattedHerbStockInId);
}

const formatHerbStockAdjustId = (id: HerbStockAdjust['id']): string => {
    return formatId('SA', id);
}

const parseHerbStockAdjustId = (formattedHerbStockAdjustId: string): HerbStockIn['id'] => {
    return parseId('SA', formattedHerbStockAdjustId);
}

const formatHerbStockCheckId = (id: HerbStockIn['id']): string => {
    return formatId('ST', id);
}

const parseHerbStockCheckId = (formattedHerbStockInId: string): HerbStockIn['id'] => {
    return parseId('ST', formattedHerbStockInId);
}

const formatConsultationId = (id: Consultation['id']): string => {
    return 'C' + `${id}`.padStart(9, '0');
}

const parseConsultationId = (formattedConsultationId: string): Consultation['id'] => {
    return parseInt(formattedConsultationId.replace('C', ''));
}

const formatAppointmentId = (id: Appointment['id']): string => {
    return 'R' + `${id}`.padStart(9, '0');
}

const formatDoctorPrescriptionId = (id: DoctorPrescription['id']): string => {
    return '' + `${id}`.padStart(5, '0');
}

const parseAppointmentId = (formattedAppointmentId: string): Appointment['id'] => {
    return parseInt(formattedAppointmentId.replace('R', ''));
}

const formatPrescriptionId = (prescription: { consultationId: Consultation['id'], index: number }): string => {
    return `${formatConsultationId(prescription.consultationId)}-P${`${prescription.index + 1}`.padStart(2, '0')}`;
}

const formatPrescriptionIdV2 = (consultationId: Consultation['id'], index: number): string => {
    return formatPrescriptionId({ consultationId, index });
}

const parsePrescriptionId = (formattedPrescriptionId: string): { consultation_id: Consultation['id'], index: number } => {
    const [c, p] = formattedPrescriptionId.split('-');
    return {
        consultation_id: parseInt(c.replace('C', '')),
        index: parseInt(p.replace('P', '')) + 1,
    };
}

const shrinkUUID = (id: PackagePurchaseRequest['id']): string => {
    return id.substring(0, 8).toLocaleUpperCase();
}

export const idGenerator = {
    formatPatientId,
    formatHerbStockOrderId,
    formatHerbStockCheckId,
    formatHerbStockInId,
    formatHerbStockAdjustId,
    formatPaymentId,
    formatConsultationId,
    formatPrescriptionId,
    formatPrescriptionIdV2,
    formatDoctorPrescriptionId,
    formatAppointmentId,
    parseConsultationId,
    parseAppointmentId,
    parsePrescriptionId,
    parseHerbStockOrderId,
    parseHerbStockInId,
    parseHerbStockCheckId,
    parseHerbStockAdjustId,
    shrinkUUID,
};