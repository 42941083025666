<script lang="ts">
import { Options, Vue, Watch } from 'vue-property-decorator';
import { defaultLocale, Locale } from '@model/constants';
import { PaymentSplitReceiptCreateDTO } from '@model/dtos';
import { usePatientHistoryDataviewStore } from '@/stores/modules/dataview';
import { Prescription, Consultation, Appointment, Payment } from '@model/entities';
import { convert2dp, sum } from '@model/utils';
import date from '@model/utils/date';
import { ListenEvent, PaymentService } from '@/services';
import { notify } from '@/utils/notify';
import { printPaymentsByParams, printPrescriptionsByParams } from '@/utils/print';

@Options({
    components: {},
})
export default class PatientHistoryTabSplitReceipt extends Vue {
    store = usePatientHistoryDataviewStore();
    locale: Locale = defaultLocale;

    // readonly availableUsageOption: Prescription['usage'][] = [
    //     {
    //         is_required: true,
    //         day: 2,
    //         is_multiple_per_day: true,
    //         times: 2,
    //         pack: 1,
    //         pack_unit: '包',
    //     },
    //     {
    //         is_required: true,
    //         day: 2,
    //         is_multiple_per_day: true,
    //         times: 2,
    //         pack: 1,
    //         pack_unit: '包',
    //     },
    // ];
    isEditing = false;
    receiptCount = 2;
    hasExistingSplitReceipt = false;
    formData: PaymentSplitReceiptCreateDTO = {
        payment_id: null,
        data: [],
    };

    get appointment(): Appointment {
        return this.store.currentConsultation?.appointment;
    }

    get consultation(): Consultation {
        return this.store.currentConsultation?.consultation;
    }

    get prescriptions(): Prescription[] {
        return this.store.currentConsultation?.prescriptions;
    }

    get payment(): Payment {
        return this.store.currentConsultation?.payment;
    }

    @Watch('store.activeTab')
    @ListenEvent('patient-history-tab:change')
    async listenPatientHistoryTabChange() {
        console.log('refresh');
        if (this.store.activeTab === 'split-receipt') {
            await this.refresh();
        }
    }

    async refresh() {
        this.formData = await PaymentService.getSplitReceipt(this.payment.id);
        this.hasExistingSplitReceipt = this.formData.data.length > 0;
    }

    handleClickConfirmCount() {
        this.formData.payment_id = this.payment.id;
        this.formData.data = [];
        const originalPrescriptionDate = Math.floor(this.prescriptions.map((p) => p.usage.day).reduce(sum, 0) / this.receiptCount) || 2; // 原定總日數
        let _date = date.toDayjs(this.appointment.date);
        const originalPaymentCount = convert2dp((this.payment.grand_total ?? 0) / this.receiptCount, undefined, true) || 300;
        for (let i = 0; i < this.receiptCount; i++) {
            this.formData.data.push({
                date: _date.format('YYYY-MM-DD'),
                diagnosis: this.consultation.diagnosis_ill,
                prescriptionIndex: 0,
                prescriptionDays: originalPrescriptionDate,
                pricingItems: [
                    {
                        name: '診金連藥費',
                        amount: originalPaymentCount,
                    },
                ],
            });
            _date = _date.add(originalPrescriptionDate, 'day');
        }
    }

    addPricingItem(index: number) {
        this.formData.data[index].pricingItems.push({
            name: '',
            amount: 0,
        });
    }

    queryAvailablePricingItemOptions(queryString: string, cb: any) {
        cb([
            {
                value: '診金連藥費',
                label: '診金連藥費',
            },
            {
                value: '針灸治療',
                label: '針灸治療',
            },
            {
                value: '綜合治療',
                label: '綜合治療',
            },
            {
                value: '拔罐',
                label: '拔罐',
            },
            {
                value: '放血治療',
                label: '放血治療',
            },
            {
                value: '天灸',
                label: '天灸',
            },
            {
                value: '其他：',
                label: '其他：',
            },
        ]);
    }

    async onClickSubmit() {
        if (await this.$confirm('確定要儲存收據匯整嗎？')) {
            await PaymentService.splitReceipt(this.formData);
            notify.success('儲存成功');
            await this.refresh();
        }
    }

    async onClickDeleteSplitReceipt() {
        if (await this.$confirm('確定要刪除收據匯整嗎？')) {
            await PaymentService.deleteSplitReceipt(this.payment.id);
            notify.success('刪除成功');
            await this.refresh();
        }
    }

    async onClickBeginEdit() {
        this.isEditing = true;
    }

    async onClickPrintPrescriptions() {
        const params = await PaymentService.printSplitReceiptPrescriptions(this.payment.id);
        await printPrescriptionsByParams({
            params,
            locale: 'zh',
        });
    }

    async onClickPrintPayments() {
        const params = await PaymentService.printSplitReceiptPayments(this.payment.id);
        await printPaymentsByParams({
            params: params,
            locale: 'zh',
        });
    }

    async onClickSubmitEdit() {
        if (await this.$confirm('確定要儲存收據匯整嗎？')) {
            await PaymentService.deleteSplitReceipt(this.payment.id);
            await PaymentService.splitReceipt(this.formData);
            notify.success('儲存成功');
            await this.refresh();
            this.isEditing = false;
        }
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-print(label="收據匯整" name="split-receipt")
    //- div {{ consultation?.prescriptions }}
    div#warn(v-if="!payment") 沒有付款資料
    .tab-container(v-else)
        el-row(style="width: 100%;")
            el-form(:model="formData" label-position="right" label-width="100px" style="width: 100%;")
                el-form-item(label="收據數量" v-if="!hasExistingSplitReceipt")
                    el-input-number(v-model="receiptCount" :min="1" :max="10" label="收據數量" style="width: 100px;")
                    el-button(@click="handleClickConfirmCount") 確認 
                    div#warn(v-if="!consultation") 沒有診症資料
                    div#warn(v-else-if="!prescriptions?.length") 沒有處方資料                   
                el-form-item(v-for="(item, formIndex) in formData.data" :key="index" :label="'收據' + (index + 1)" label-width="0")
                    el-row(style="width: 100%;")
                        el-col
                            el-row
                                el-col
                                    el-form-item(label="日期")
                                        el-date-picker(v-model="item.date" type="date" placeholder="選擇日期" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
                            el-row
                                el-col
                                    el-form-item(label="診斷")
                                        el-input(v-model="item.diagnosis" placeholder="輸入診斷" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
                            el-row(v-if="prescriptions?.length > 1")
                                el-col
                                    el-form-item(label="處方")
                                        el-select(v-model="item.prescriptionIndex" placeholder="選擇處方" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
                                            el-option(v-for="(_, index) in prescriptions" :key="index" :label="'處方' + (index + 1)" :value="index")
                            el-row(v-if="prescriptions?.length > 0")
                                el-col
                                    el-form-item(label="用法")
                                        el-select(v-model="item.prescriptionDays" placeholder="選擇用法" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
                                            el-option(v-for="index in 7" :key="index" :label="`內服 ${index} 天, 每天 2 次, 每次 1 包, 共 ${2 * (index)} 包`" :value="index" v-show="index >= 2")
                            el-row
                                el-col
                                    el-form-item(label="收費項目")
                                        el-table(:data="item.pricingItems" border style="width: 100%;")
                                            el-table-column(label="" width="100px" v-if="!hasExistingSplitReceipt")
                                                template(#default="{ row, $index }")
                                                    el-button(type="danger" size="small" @click="item.pricingItems.splice($index, 1)" v-if="item.pricingItems.length > 1") -
                                                    el-button(type="primary" size="small" v-if="$index === item.pricingItems.length - 1" @click="addPricingItem(formIndex)") +
                                            el-table-column(label="項目")
                                                template(#default="{ row }")
                                                    el-autocomplete(v-model="row.name" :fetch-suggestions="queryAvailablePricingItemOptions" placeholder="輸入項目" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
                                            el-table-column(label="價格" prop="amount")
                                                template(#default="{ row }")
                                                    el-input-number(v-model="row.amount" :min="0" style="width: 100%;" :disabled="hasExistingSplitReceipt && !isEditing")
        template(v-if="!hasExistingSplitReceipt")
            el-button(type="primary" @click="onClickSubmit" :disabled="formData.data.length === 0") 儲存
        template(v-else)
            el-button(type="primary" @click="onClickBeginEdit" v-if="!isEditing") 更改
            el-button(type="primary" @click="onClickSubmitEdit" v-else="isEditing") 儲存
            el-button(type="primary" @click="onClickPrintPayments") 列印收據
            el-button(type="primary" @click="onClickPrintPrescriptions") 列印處方
            el-button(type="danger" @click="onClickDeleteSplitReceipt") 刪除

</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';

.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

#warn {
    color: red;
    margin-left: 5px;
    font-size: 12px;
}
</style>
printPaymentsByParamsprintPaymentsByParamsprintPaymentsByParams