import { defineStore } from 'pinia';
import { store } from '@/stores';
import { useCache } from '@/hooks/useCache';
import { Appointment, AppointmentAttachment, Consultation, Doctor, HerbBrand, Patient, Payment } from '@model/entities';
import { ConsultationUpsertDTO } from '@model/dtos';
import { eventEmitter } from '@/services/events';
import { AppointmentService, ConsultationService, PatientService, PricingService } from '@/services';
import { cloneDeep } from 'lodash';
import { ConsultationDiagnosisFacadeStateVO, PrescriptionFacadeStateVO } from '@model/vos';
import { AnyColumn } from 'element-plus/es/components/table-v2/src/common';
import date from '@model/utils/date';
import { notify } from '@/utils/notify';
import { useSelectItemStore } from '../select-item';
import { SelectItem } from '@model/models';
import { PrescriptionFacadeState } from '../data/prescription-facade.store';
import { handleError } from '@/utils/handleError';

const { wsCache } = useCache('sessionStorage');
interface State {
    _patientId: number;
    _patient: Patient;
    _herbBrandItems: SelectItem<HerbBrand['id']>[],
    _consultationList: {
        id: Consultation['id'],
        consult_dt?: Date,
    }[];
    _currentConsultation: any;
    loading: boolean;
    currentConsultationId: number;
    prescriptionIndex: number;
    activeTab: string | null;
}

const defaultState: State = {
    loading: null,
    _patientId: null,
    _patient: null,
    _herbBrandItems: [],
    _consultationList: [],
    _currentConsultation: null,
    activeTab: null,
    currentConsultationId: null,
    prescriptionIndex: 1,
}

export const usePatientHistoryDataviewStore = defineStore({
    id: 'patient-history-dataview',
    state: (): State => cloneDeep(defaultState),
    getters: {
        patientId(): number {
            return this._patientId;
        },
        consultationList(): { id: Consultation['id'], consult_dt?: Date }[] {
            return this._consultationList;
        },
        hasConsultation(): boolean {
            return this._consultationList.length > 0;
        },
        patient(): Patient {
            return this._patient;
        },
        payment(): Payment {
            return this._currentConsultation.payment;
        },
        currentConsultation(): any | null {
            return this._currentConsultation;
        },
        currentPrescription(): PrescriptionFacadeState['prescriptions'][number] {
            if (this.currentConsultation?.prescriptions.length >= this.prescriptionIndex) {
                return this.currentConsultation?.prescriptions[this.prescriptionIndex - 1];
            }
            return null;
        },
        attachments(): AppointmentAttachment[] {
            return this._currentConsultation?.attachments ?? [];
        },
        sickLeaveCount(): number {
            if (!this.currentConsultation || !this.currentConsultation.diagnosis._sickLeaveDateRange) {
                return 0;
            }
            try {
                return date.getSickLeaveDays(this.currentConsultation.diagnosis._sickLeaveDateRange[0], this.currentConsultation.diagnosis._sickLeaveDateRange[1], {
                    sick_leave_start_from_pm: this.currentConsultation.diagnosis.sick_leave_start_from_pm,
                    sick_leave_end_at_am: this.currentConsultation.diagnosis.sick_leave_end_at_am,
                });
            } catch (e) {
                notify.error(e.message);
                return 0;
            }
        },
    },
    actions: {
        reset() {
            const defaultVal = cloneDeep(defaultState);
            for (const x of Object.keys(defaultVal)) {
                this[x] = defaultVal[x];
            }
        },
        async init(patientId: number) {
            try {
                this.loading = true;
                this._patientId = patientId;
                this._patient = await PatientService.one(patientId);
                this._consultationList = await PatientService.listConsultations(patientId);
                if (this._consultationList.length > 0) {
                    await this.loadConsultation(this._consultationList[0].id);
                } else {
                    this._currentConsultation = null;
                }
                const selectItemStore = useSelectItemStore();
                this._herbBrandItems = await selectItemStore.getHerbBrands();
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        },
        async loadConsultation(id) {
            if (id === this.currentConsultationId) {
                return;
            }
            this.currentConsultationId = id;
            this.prescriptionIndex = 1;
            try {
                this.loading = true;
                const consultation = await ConsultationService.getHistory(id);
                const selectItemStore = useSelectItemStore();
                this._herbBrandItems = await selectItemStore.getHerbBrands();
                this._currentConsultation = {
                    appointment: consultation.appointment,
                    consultation: consultation,
                    diagnosis: ConsultationDiagnosisFacadeStateVO.fromEntity(consultation),
                    acu: consultation.acu,
                    massage: consultation.massage,
                    prescriptions: PrescriptionFacadeStateVO.fromEntities(consultation.prescriptions, this._herbBrandItems),
                    payment: consultation.appointment?.payment,
                    attachments: consultation.appointment?.attachments,
                };
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        },
    }
});

export const usePatientHistoryDataviewStoreWithOut = () => {
    return usePatientHistoryDataviewStore(store);
};
