import { BaseService } from "./base.service";
import { AppointmenReasonUpdateDTO, AppointmentPreValidateDTO, AppointmentQueryDTO, AppointmentPlaceholderCreateDto, AppointmentRemarkUpdateDTO, AppointmentAttachmentCreateDTO, AppointmentAttachmentUpdateDTO, AppointmentRevertCompletedDTO, AppointmentAvailableTimeslotQueryDTO } from "@model/dtos";
import { AppointmentPaymentResponseData, AppointmentPaymentListResponse, AppointmentConsultationResponseData, AppointmentQueryResponse, AppointmentQueryResponseData, AppointmentTreatmentResponseData, AppointmentPlaceholderCreateResponse, AdvancedResponse, AppointmentConsultationListResponse } from "@model/responses";
import { Appointment, Patient, AppointmentAttachment } from "@model/entities";
import { AppointmentStatus } from "@model/models";

class Service extends BaseService {
    async preValidate(body: AppointmentPreValidateDTO): Promise<AdvancedResponse<void>> {
        return this.post(`/pre-validate`, body);
    }

    async fetchArrived(query: AppointmentQueryDTO): Promise<AppointmentConsultationListResponse> {
        return this.get(`/is/arrived?${this.parseQuery(query)}`);
    }

    async fetchPayment(query: AppointmentQueryDTO): Promise<AppointmentPaymentListResponse> {
        return this.get(`/is/payment?${this.parseQuery(query)}`);
    }

    async fetchTreatment(query: AppointmentQueryDTO): Promise<AppointmentTreatmentResponseData[]> {
        return this.get(`/is/treatment?${this.parseQuery(query)}`);
    }

    async fetchPatient(patientId: Patient['id']): Promise<Appointment[]> {
        return this.get(`/patient/${patientId}`);
    }

    async getAttachmentsByAppointmentId(id: Appointment['id']): Promise<AppointmentAttachment[]> {
        return this.get(`/attachment/${id}`);
    }

    async createAttachment(body: AppointmentAttachmentCreateDTO): Promise<AppointmentAttachment> {
        return this.postForm(`/attachment`, body);
    }

    async updateAttachment(body: AppointmentAttachmentUpdateDTO): Promise<AppointmentAttachment> {
        return this.post(`/attachment/update`, body);
    }

    async deleteAttachment(id: AppointmentAttachment['id']): Promise<void> {
        return this.delete(`/attachment/${id}`);
    }

    async query(query: AppointmentQueryDTO): Promise<AppointmentQueryResponse> {
        try {
            return this.get(`/query?${this.parseQuery(query, ['date'])}`);
        } catch (e) {
            if (e.message === 'Key date does not exist') {
                return;
            }
            throw e;
        }
    }

    async oneQuery(appointmentId: number): Promise<AppointmentQueryResponseData> {
        return this.get(`/one/query/${appointmentId}`);
    }

    async getFollowupAppointmentOf(appointmentId: number): Promise<Appointment> {
        return this.get(`/followup-of/${appointmentId}`);
    }

    async oneConsultation(appointmentId: number): Promise<AppointmentConsultationResponseData> {
        return this.get(`/one/consultation/${appointmentId}`);
    }

    async onePayment(appointmentId: number): Promise<AppointmentPaymentResponseData> {
        return this.get(`/one/payment/${appointmentId}`);
    }

    async oneTreatment(appointmentId: number): Promise<AppointmentTreatmentResponseData> {
        return this.get(`/one/treatment/${appointmentId}`);
    }

    async setStatus(id: Appointment['id'], status: AppointmentStatus, reason?: string): Promise<AppointmentQueryResponseData> {
        return this.post(`/status/${id}/${status}`, { reason });
    }

    async updateRemark(body: AppointmentRemarkUpdateDTO): Promise<AppointmentQueryResponseData> {
        return this.post(`/remark`, body);
    }

    async updateReason(body: AppointmenReasonUpdateDTO): Promise<AppointmentQueryResponseData> {
        return this.put(`/reason`, body);
    }

    async revertStatus(id: Appointment['id'], status: AppointmentStatus, reason?: string): Promise<AppointmentQueryResponseData> {
        return this.post(`/status-revert/${id}/${status}`, { reason });
    }

    async createPlaceholder(body: AppointmentPlaceholderCreateDto): Promise<AppointmentPlaceholderCreateResponse> {
        return this.put(`/placeholder`, body);
    }

    async revertCompleted(body: AppointmentRevertCompletedDTO): Promise<void> {
        return this.post(`/revert-completed`, body);
    }

    async getAvailableTimeslots(body: AppointmentAvailableTimeslotQueryDTO): Promise<void> {
        return this.post(`/available-timeslot`, body);
    }

    async getPrescriptionCount(appointmentId: Appointment['id']): Promise<number> {
        return this.get(`/prescription-count/${appointmentId}`);
    }

    async label(appointmentId: Appointment['id']) {
        this.attemptOpenWithEdge(`/label/${appointmentId}`);
    }
}

export const AppointmentService = new Service('/appointment');