import { BaseService } from "./base.service";
import { Appointment, Payment, PaymentDiscountItem, PaymentItem, PaymentSplitReceipt, Prescription } from "@model/entities";
import { CreatePaymentSplitReceiptDTO, PaymentCreateDTO, PaymentPrescriptionBreakdownDTO } from "@model/dtos";
import { PaymentMethodType } from "@model/models";
import { PaymentPrescriptionBreakdownResponse } from "@model/responses";
import { PaymentPrescriptionsPreviewDTO, PaymentPackagePreviewDTO } from "@model/vos";
import { Locale } from "@model/constants";

class Service extends BaseService {
    async save(body: PaymentCreateDTO): Promise<Payment> {
        return this.put(``, body);
    }

    async exit(id: Appointment['id']): Promise<void> {
        return this.get(`/exit/${id}`);
    }

    async oneByAppointmentId(id: Appointment['id'], locale?: Locale): Promise<Payment> {
        return this.get(`/appointment/${id}?${this.parseQuery({ locale })}`);
    }

    async oneByPrescriptionId(id: Prescription['id']): Promise<Payment> {
        return this.get(`/prescription/${id}`);
    }

    /**
     * @param body.clinicId: Appointment Clinic Id
     * @returns 
     */
    async previewPrescriptions(body: PaymentPrescriptionsPreviewDTO): Promise<PaymentItem[]> {
        return this.post('/preview/prescriptions', body);
    }

    async breakdownPrescription(body: PaymentPrescriptionBreakdownDTO): Promise<PaymentPrescriptionBreakdownResponse[]> {
        return this.post('/preview/breakdown-pieces', body);
    }

    async previewConsultation(body: { clinic_id, doctor_id, patient_id }): Promise<{ items: PaymentItem[], discounts: PaymentDiscountItem[] }> {
        return this.post('/preview/consultation', body);
    }

    async previewPackage(body: PaymentPackagePreviewDTO): Promise<{ items: PaymentItem[], discounts: PaymentDiscountItem[] }> {
        return this.post('/preview/packages', body);
    }

    async updateMethod(id: Payment['id'], method: PaymentMethodType): Promise<PaymentMethodType> {
        return this.post('/method', { method, id });
    }

    async revertToPaymentStatus(appointmentId: Appointment['id']): Promise<Payment> {
        return this.post(`/revert/${appointmentId}`);
    }

    async getSplitReceipt(paymentId: Payment['id']): Promise<PaymentSplitReceipt[]> {
        return this.get(`/split/${paymentId}`);
    }

    async splitReceipt(body: CreatePaymentSplitReceiptDTO): Promise<Payment[]> {
        return this.post(`/split`, body);
    }

    async printSplitReceiptPayments(paymentId: Payment['id']): Promise<any[]> {
        return this.get(`/split/print/payments/${paymentId}`);
    }

    async printSplitReceiptPrescriptions(paymentId: Payment['id']): Promise<any[]> {
        return this.get(`/split/print/prescriptions/${paymentId}`);
    }

    async deleteSplitReceipt(paymentId: Payment['id']): Promise<void> {
        return this.delete(`/split/${paymentId}`);
    }
}

export const PaymentService = new Service('/payment');

